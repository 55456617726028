html, body {
    overflow-x: hidden;
}

body {
    background: #111111;
    background-size: cover;
    overflow-y: hidden; 
    color: white;
    overflow-x: hidden;
}

.main-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3rem;
    height: 100vh;
}

.content-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-self: center;
    max-width: 700px;
    padding: 2vw 0 0;
}


.app-logo {
    filter: drop-shadow(100px 100px 100px #0D071C12);
    border-radius: 20px;
    width: 30vw;
}

.watch-img {
    width: 25vw;
}

.title-text {
    font-family: Manrope;
    font-size: 34px;
    font-weight: 800;
    line-height: 46px;
    letter-spacing: -1.5839999914169312px;
    text-align: center;
}


.description-div{
    margin-top: 2rem;
    text-align: center;
    line-height: 1.2em;
    max-height: 2.4em; 
    overflow: hidden;
    font-family: Manrope;
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 2rem;
}

.second-description-div {
    margin-top: 2rem;
    font-family: Manrope;
    font-size: 18px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: center;
}


p.p1 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px 'Helvetica Neue'}
p.p2 {margin: 0.0px 0.0px 0.0px 0.0px; font: 13.0px 'Helvetica Neue'; min-height: 15.0px}


.image-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30vw;
    margin-bottom: 20px;
}

.image-container img {
    width: 100%;
    display: block;
}

.image-container::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50%;
    background: linear-gradient(to bottom, rgba(17, 17, 17, 0.05) 7%, rgba(17, 17, 17, 0.5) 15%, rgba(17, 17, 17, 0.9) 40%, rgba(17, 17, 17, 1) 50%);
    pointer-events: none;
}

.cta {
    color: #fff;
    text-align: center;
    margin-top: -12vw;
    z-index: 999;
}

.mobile-break {
    display: none;
}


/* ===================== */
.footer, .footer-mobile {
    margin-top: 1.5rem;
    background-color: #111;
    color: #fff;
    text-align: center;
    padding: 20px 0;
    font-family: Arial, sans-serif;
    z-index: 999;
}

.footer-buttons {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
}

.footer-button, .footer-mobile-button {
    background-color: #333;
    color: #fff;
    padding: 10px 20px;
    text-decoration: none;
    border-radius: 9px;
    transition: background-color 0.3s ease;
    width: 180px;
    text-align: left;
    display: inline-flex;
    align-items: center;
    justify-content: left;
    gap: 10px;
}

.footer-button:hover {
    background-color: #444;
}

.footer-info {
    margin-top: 1.5vw;
    font-size: 14px;
}

.footer-info p {
    margin: 5px 0;
    color: #FFFFFF80;
}

.footer-info a {
    color: #FFFFFF80;
    text-decoration: none;
}

.footer-info a:hover {
    text-decoration: underline;
}

.rights-reserved {
    margin-top: 1.5vw !important;
    font-size: 14px;
}

.footer-mobile {
    display: none;
}

/* Mobile Styles */
@media (max-width: 768px) {

    .footer {
        display: none;
    }

    .main-container {
        padding: 0;
    }

    .content-div {
        padding: 4vw 0 0;
    }
    
    .app-logo {
        width: 80%;
    }

    .watch-img {
        width: 100%;
    }
    
    .title-text {
        font-size: 28px;
        line-height: 36px;
    }

    .description-div {
        font-size: 14px;
    }

    .second-description-div {
        font-size: 16px;
    }

    .image-container {
        width: 80%;
    }

    .cta {
        margin-top: -40vw;
    }

    .cta p {
        font-size: 18px;
    }

    .cta img {
        width: 70%!important;
    }

    .mobile-break {
        display: inline;
    }
    .footer-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;
        box-sizing: border-box;
        width: 100%;
    }
    
    .footer-row {
        display: flex;
        justify-content: flex-start;
        width: 100%;
    }
    
    .footer-mobile-button {
        display: block;
        text-align: left;
        padding: 10px;
        text-decoration: none;
        color: #FFFFFF;
        border-radius: 15px;
        width: 40vw;
        font-size: 0.8rem;
    }
    
    .footer-mobile-info {
        font-size: 12px;
        color: #FFFFFF80;
        padding: 5px 10px;
        width: 100%;
        text-align: left;
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        box-sizing: border-box;
    }
    
    .footer-mobile-info a {
        text-decoration: none;
        color: #FFFFFF80;
    }
    
    .footer-mobile-info p {
        margin: 0;
    }
    
    .rights-reserved {
        margin-top: 10px;
        font-size: 12px;
        color: #FFFFFF80;
    }
    
    table td {
        padding: 7px;
    }
}
